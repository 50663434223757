import Location from './Location'

export default class Autocomplete extends Location {
  constructor(element, map=null) {
    super(element)

    this.submitOnChange = element.hasAttribute('data-submit-on-change')
    this.map = map
    this.autocomplete = new google.maps.places.Autocomplete(this.placeInput)
    this.autocomplete.setComponentRestrictions({ country: ['fr'] })
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace()
      if (this.map !== null) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
          const url = new URL(window.location.href);
          url.searchParams.set('address', this.placeInput.value);
          window.history.replaceState(null, null, url);
          this.setPlace(place, place.geometry.viewport)
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }
      } else {
        this.setPlace(place)
      }
      this.emit('change', { place })

      if (this.submitOnChange) {
        this.placeInput.form.submit()
      }
    })
  }
}
