export default function () {
  function CheckPrivateLand() {
    if (document.getElementById('demand_offer_type_land').checked) {
      document.getElementById('demand_land').checked = true
    } else {
      document.getElementById('demand_land').checked = false
    }
    false
  }
  CheckPrivateLand()
  $('#demand_offer_type_land').change(function () {
    CheckPrivateLand()
  });
}