import France from './France'
import Autocomplete from './Autocomplete'
import Suggestions from './Suggestions'
import PlaceMap from './PlaceMap'

export default class PlaceType
{
  constructor(element) {
    this.element = element

    this.location = new Autocomplete(element)
    this.location.disableValidatedInput()
    this.location.on('change', ({ place }) => {
      this.location.enableValidatedInput()
      this.suggestions.getSuggestion(place)
    })

    const map = element.querySelector('.map')

    if (map) {
      this.map = new PlaceMap(map)
      this.map.init(France.lat, France.lng, 14)
      this.map.hide()
      this.map.showLocation(this.location)
      this.map.on('markerMoved', ({ latLng }) => {
        this.map.showMarker(latLng.lat(), latLng.lng())
        this.location.move(latLng.lat(), latLng.lng())
      })
      this.location.on('change', ({ place }) => this.map.showPlace(place))
      this.location.on('validated', () => {
        if (window.location.href.includes("offres")) {
          this.map.hide()
        }

        if (this.map.dataShape[0]) {
          this.map.dataShape[0].setOptions({editable: false, draggable: false})
        }
        if (this.map.drawingManager) {
          this.map.drawingManager.setMap(null)
        }
      })
      this.location.on('unvalidated', () => {
        if (this.map.dataShape[0]) {
          this.map.dataShape[0].setOptions({editable: true, draggable: true})
        }
        if (this.map.drawingManager) {
          this.map.drawingManager.setMap(this.map.map)
        }
        this.map.marker ? this.map.show() : null
      })

      if (this.location.lat() && this.location.lng()) {
        this.location.enableValidatedInput()
      }
    } else {
      this.map = null
    }

    this.suggestions = new Suggestions(element)
  }

}
