<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
import * as _ from 'underscore'

export default {
  props: {
    id: { type: String, required: true },
    endpoint: { type: String, required: true },
    value: { /*type: String,*/ required: true },
    name: { type: String, required: true },
    max: { type: Number, require: false, default: null },
    placeholder: { type: String, require: false, default: null },
    required: { type: Boolean, require: false, default: false },
  },
  data() {
    return {
      search: null,
      showSuggestions: false,
      suggestions: [],
      selection: JSON.parse(this.value),
      json: this.value,
      loading: false,
      no_data: false
    }
  },
  computed: {
    allowAdd() {
      return null === this.max || this.selection.length < this.max
    },
  },
  created() {
    this.debounceGetSuggestions = debounce(this.getSuggestions, 500)

  },
  methods: {
    getSuggestions() {
      this.loading = true

      axios
        .get(this.endpoint, { params: { search: this.search, ids: _.pluck(this.selection, 'id'), company_list: _.pluck(this.selection, 'company') } })
        .then(response => {

          if (response.data.length > 0) {
            this.suggestions = response.data
            this.loading = false
            this.no_data = false
          } else {
            this.suggestions = []
            this.loading = false
            this.no_data = true
          }
        })
        .catch(error =>{ this.loading = false
                          console.error(error)
                      })
    },
    add(user) {
      if (this.allowAdd && !this.selected(user)) {
        this.selection.push(user)
        this.showSuggestions = false
        this.search = ""
      }
    },
    remove(user) {
      this.selection = this.selection.filter(item => item.id !== user.id)
      this.showSuggestions = false
      this.search = ""
    },
    selected(user) {
      return undefined !== this.selection.find(item => item.id === user.id)
    },
    hideSuggestion() {
      this.showSuggestions = false
    },
    toggle(user) {
      if (this.selected(user)) {
        this.remove(user)
      } else {
        this.add(user)
      }
    },
    enter() {
      if (this.suggestions.length === 1 && !this.selected(this.suggestions[0])) {
        this.add(this.suggestions[0])
        this.search = null
      }
    },
  },
  watch: {
    search() {
      if (this.search === null ) {
        this.suggestions = []
        return
      }
      this.debounceGetSuggestions()
    },
    showSuggestions() {
      if (this.showSuggestions) {
        document.addEventListener('click', this.hideSuggestion)
      } else {
        document.removeEventListener('click', this.hideSuggestion)
      }
    },
    selection(value) {
      this.json = JSON.stringify(value)

      // Trigger change event on input in order to be listen by outside component javascript
      this.$nextTick(() => this.$refs.json.dispatchEvent(new Event('change')))
    },
    allowAdd(value) {
      if (!value) {
        this.search = null
        this.suggestions = []
      } else {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
  }
}
</script>

<template>
  <div class="dropdown-autocomplete dropdown-autocomplete--multiple" :id="id">
    <input type="hidden" v-model="json" ref="json" :name="name" :required="required" :id="`${id}_data`">
    <div class="dropdown-autocomplete__result">
      <div v-for="user in selection" class="dropdown-autocomplete__input contact-selected" v-bind:key="user.id" >
        <strong>{{ user.firstname }} {{ user.lastname }}</strong>
        <span>{{ user.company }}</span>
        <button type="button" v-on:click="remove(user)" v-on:click.stop>
          <i class="icon icon--cross" aria-hidden="true"></i>
          <span class="screen-reader">Supprimer</span>
        </button>
      </div>
    </div>
    <input
      class="dropdown-autocomplete__input"
      type="search"
      ref="search"
      :placeholder="placeholder"
      v-model="search"
      @input="search = $event.target.value"
      v-show="allowAdd" v-on:click.stop
      v-on:keydown.enter.prevent="enter()"
      v-on:focus="()=>{ showSuggestions = true;  this.debounceGetSuggestions();}"
    >
    <div class="dropdown-autocomplete__menu" v-show="showSuggestions " v-on:click.stop>
      <div v-show="loading && suggestions.length < 1" class="item">Chargement...</div>
      <div v-show="no_data && !loading" class="item">
      <span >
        <strong>Aucun contacts</strong>
      </span>
    </div>
      <div v-for="suggestion in suggestions" class="item" v-on:click="toggle(suggestion)"  v-bind:key="suggestion.id" v-show="!selected(suggestion)">
        <span  v-on:click.stop="add(suggestion)" :disabled="!allowAdd">
          <strong>{{ suggestion.firstname }} {{ suggestion.lastname }}</strong>
          {{ suggestion.company }}
        </span>
      </div> 
    </div>
  </div>
</template>
