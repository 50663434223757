class Show
{
  constructor(element, config, reverse = false) {
    this.element = element
    this.config = config.value.split(',')
    this.reverse = reverse
    this.inputs = document.querySelectorAll(config.input)
    this.inputs.forEach(input => input.addEventListener('change', () => this.onChange()))
    this.onChange()
  }

  getValues(inputs) {
    return [...inputs].filter(input => input.checked).map(input => input.value)
  }

  onChange() {
    valuesInputs = this.getValues(this.inputs)
    const CheckIncludes = this.config.every(value => {
      return valuesInputs.includes(value);
    });
    this.toggle(CheckIncludes)
  }

  toggle(condition) {
    if (this.reverse) {
      condition = !condition
    }

    if (condition) {
      this.element.style.display = null
    } else {
      this.element.style.display = 'none'
    }
  }
}
$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-show]').forEach(element => new Show(
    element,
    JSON.parse(element.getAttribute('data-show')),
  ))

  document.querySelectorAll('[data-hide]').forEach(element => new Show(
    element,
    JSON.parse(element.getAttribute('data-hide')),
    true,
  ))
});
