import GMap from './GMap'

export default class PlaceMap extends GMap
{
  constructor(element) {
    super(element, false)

    this.hide()
    this.allowMoveMarker = this.element.hasAttribute('data-allow-move-marker')
    this.marker = null
  }

  init(lat, lng, zoom) {
    super.init(lat, lng, zoom)

    if (this.allowMoveMarker) {
      // The following code prevent marker move when zooming with double click
      let timeout = null

      this.map.addListener('click', ({ latLng }) => {
        timeout = setTimeout(() => {
          this.emit('markerMoved', { latLng })
        }, 200)
      })

      this.map.addListener('dblclick', () => clearTimeout(timeout))
    }
  }

  createMarker() {
    if (this.marker === null) {
      this.marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29),
        icon: new google.maps.MarkerImage(this.markerIcon),
      })
    }
  }

  hideMarker() {
    if (this.marker) {
      this.marker.setVisible(false)
    }
  }

  showLocation(location) {
    const lat = location.lat()
    const lng = location.lng()
    this.showMarker(lat, lng, true)
  }

  showMarker(lat, lng, center = false) {
    if (!lat || !lng) {
      return
    }

    this.createMarker()
    this.marker.setPosition({ lat, lng })
    this.marker.setVisible(true)

    if (center) {
      this.map.setCenter({ lat, lng })
    }

    this.show()
  }

  showPlace(place) {
    this.hideMarker()

    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'")
      return
    }

    if (place.geometry.viewport) {
      setTimeout(() => {
        this.map.fitBounds(place.geometry.viewport)
      }, 0);
    } else {
      this.map.setCenter(place.geometry.location)
    }

    this.showLocation(place.geometry.location)
  }

  show() {
    this.element.style.display = null
  }

  hide() {
    this.element.style.display = 'none'
  }
}
