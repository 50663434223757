import Carousel from './carousel'

class Lightbox
{
  constructor(element) {
    this.element = element
    this.carousel = new Carousel(element.querySelector('.carrousel'))
    this.carousel.on('position', event => {
      this.setTitle(event.title)
      this.setPagination(event.position + 1, event.total)
      this.setDownload(event.position + 1)
    })
    this.title = element.querySelector('.title')
    this.pagination = element.querySelector('.pagination')
    this.key = this.key.bind(this)

    this.element.querySelectorAll('.close').forEach(close => {
      close.addEventListener('click', event => {
        event.preventDefault()
        this.close()
      })
    })

    this.element.addEventListener('click', event => {
      event.preventDefault()
      this.close()
    })

    this.element.querySelector('.container').addEventListener('click', event => event.stopPropagation())

    document.querySelectorAll(`[data-lightbox-trigger="#${element.getAttribute('id')}"]`).forEach((trigger) => {
      trigger.addEventListener('click', event => {
        event.preventDefault()
        const position = parseInt(trigger.getAttribute('data-lightbox-position'), 10)
        this.carousel.setPosition(position)
        this.open()
      })
    })
  }

  key(event) {
    if (event.key === 'Escape') {
      event.preventDefault()
      this.close()
    } else if (event.key === 'ArrowRight') {
      this.carousel.next(event)
    } else if (event.key === 'ArrowLeft') {
      this.carousel.previous(event)
    }
  }

  open() {
    this.element.classList.add('lightbox--show')
    document.body.classList.add('prevent-scroll')
    document.addEventListener('keydown', this.key)
  }

  close() {
    this.element.classList.remove('lightbox--show')
    document.body.classList.remove('prevent-scroll')
    document.removeEventListener('keydown', this.key)
  }

  setTitle(title) {
    this.title.innerHTML = title
  }

  setPagination(current, total) {
    this.pagination.innerHTML = `${current} / ${total}`
  }

  setDownload(current) {
    const downloadButton = this.element.querySelector('a[download]')
    const iframe = this.element.querySelectorAll('embed')[current-1]

    if (downloadButton && iframe) {
      downloadButton.setAttribute('href', iframe.src)
    }
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('.lightbox').forEach(element => new Lightbox(element));
});
