$(document).on('turbolinks:load', function () {
  if (document.getElementById('create_offer_location_validated') != null) {

    let coverAddress = document.getElementById('cover-address')
    let coverTypes = document.getElementById('cover-types')
    let validateAddress = document.getElementById('validate-address')
    const HideText = "visibility: hidden; height: 0; overflow:hidden"
    const ShowText = "visibility: initial; height: auto; overflow:initial"

    document.getElementById("offer_address").disabled == true ? hideCoverAddress(false) : hideCoverAddress(true)

    hideCoverTypes(true)

    function hideCoverTypes(state) {
      if (state) {
        coverTypes.style.cssText = HideText
      } else {
        coverTypes.style.cssText = ShowText
      }
    }

    function hideCoverAddress(state) {
      if (state) {
        coverAddress.style.cssText = ShowText
      } else {
        coverAddress.style.cssText = HideText
      }
    }

    document.getElementById('create_offer_location_validated').addEventListener('click', (element) => {
      if (element.target.checked && validateAddress.style.display == 'none') {
        hideCoverTypes(true)
        hideCoverAddress(true)
      } else {
        hideCoverTypes(false)
        hideCoverAddress(false)
      }
    })
  }
});