export default function() {
  var num = document.querySelector(
    'input[name="offer[months_after_agreement]"]'
  );

  var err = document.querySelector(".form__errors");
  err.style.display = "none";
  display(num, err);

  num.addEventListener("keyup", (event) => {
    display(event.target, err);
    // do something
  });
}

function display(element, err) {
  if(element.value){
    if (element.value >= 1 && element.value <= 36) {
      element.parentNode.classList.remove("form--error");
      err.style.display = "none";
    } else {
      element.parentNode.classList.add("form--error");
      err.style.display = "block";
    }
  }
  
}
