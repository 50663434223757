export default function () {

	var lot_max = document.querySelector(
		'input[name="offer[max_size]"]'
	);
	var lot_min = document.querySelector(
		'input[name="offer[min_size]"]'
	);

	var err = document.querySelector(".form__errors");
	if (lot_max && lot_min) {
	err.style.display = "none";
	display(lot_max, lot_min, err);

	lot_max.addEventListener("focusout", (event) => {
		display(lot_max, lot_min, err);
	});
}
	}


function display(max, min, err) {
	if (max.value && min.value) {
		if (parseInt(max.value) >= parseInt(min.value)) {
			max.parentNode.classList.remove("form--error");
			err.style.display = "none";
		} else {
			max.parentNode.classList.add("form--error");
			err.style.display = "block";
		}
	}

}