class Price
{
  constructor(totalPriceInput, sizePriceInput, size) {
    this.totalPriceInput = totalPriceInput
    this.sizePriceInput = sizePriceInput
    this.size = size

    this.totalPriceInput.addEventListener('keyup', () => this.computeFromTotalPrice())
    this.sizePriceInput.addEventListener('keyup', () => this.computeFromSizePrice())
    this.computeFromSizePrice()
  }

  computeFromSizePrice() {
    if (Number.isInteger(parseInt(this.sizePriceInput.value, 10)) && Number.isInteger(this.size)) {
      this.totalPriceInput.value = parseInt(this.sizePriceInput.value, 10) * this.size
    } else {
      this.totalPriceInput.value = null
    }
  }

  computeFromTotalPrice() {
    if (Number.isInteger(parseInt(this.totalPriceInput.value, 10)) && Number.isInteger(this.size)) {
      this.sizePriceInput.value = Math.round(parseInt(this.totalPriceInput.value, 10) / this.size)
    } else {
      this.sizePriceInput.value = null
    }
  }
}

$(document).on('turbolinks:load', function() {
  /*document.querySelectorAll('[data-price]').forEach(element => new Price(
    element,
    document.querySelector(element.getAttribute('data-price')),
    parseInt(element.getAttribute('data-size'), 10),
  ))*/
})
