class Disable {
  constructor(element) {
    this.element = element
    this.affect = document.getElementById(this.element.attributes["data-disabled"].value)
    this.element.addEventListener('change', () => this.onChange())
  }

  onChange() {
    if (this.element.checked) {
      this.affect.disabled = true
      this.affect.parentElement.classList.add("form-animated__content__disabled")
    } else {
      this.affect.disabled = null
      this.affect.parentElement.classList.remove("form-animated__content__disabled")
    }
  }
}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('[data-disabled]').forEach(element => new Disable(
    element
  ))
})