import GMap from './GMap'
import PlaceType from './PlaceType'


// $(document).on('turbolinks:load', function() {
//   document.querySelectorAll('[data-place-type]').forEach(element => new PlaceType(element))
//   document.querySelectorAll('[data-gmap]').forEach(element => new GMap(element))
// });

window.initMap = function () {
  document.querySelectorAll('[data-place-type]').forEach(element => new PlaceType(element))
  document.querySelectorAll('[data-gmap]').forEach(element => new GMap(element))
}
