class BlockComma {
  constructor(element) {
    this.input = element
    this.input.addEventListener("keypress", (event) => {
      this.checkDecimal(event)
    });
  }
  checkDecimal(event) {
    re = /^\d+$/
    if ((!re.test(event.target.value) && event.target.value != "") || event.key == "." || event.key == ",") {
      event.preventDefault()
    }
  }

}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('.block-comma').forEach(element => new BlockComma(element));
});