$(document).on('turbolinks:load', function () {
    $(document).ready(function () {

        $(".not_now").click(function () {
            localStorage.setItem('complete-register', true);
        })
        if (!localStorage.getItem('complete-register')) {
            $("#complete-register").fancybox().trigger('click')
        }

        if ($("#email-exist").length > 0) {
            $("#email-exist").fancybox().trigger('click')
        }
    })
});