/*
 * This class disable submit button until all input are valid (requirements and html5 validation)
 *
 * Usage:
 *
 * <form id="my-form">
 *   <input type="text" required>
 *   <button type="submit" data-validate="#my-form">Save</button>
 * </form>
 */
class Validate {
  constructor(button, form) {
    this.button = button
    this.form = form
    this.init()
    this.update()
  }

  init() {
    // group required input with the same name in a Map of InputGroup
    this.inputs = new Map()
    this.form.querySelectorAll('input, select, textarea').forEach(input => {
      const required = this.inputs.get(input.name)
      if (required) {
        required.push(input)
      } else {
        this.inputs.set(input.name, new InputGroup(input))
      }

      input.addEventListener('change', () => this.update())
      input.addEventListener('keyup', () => this.update())
    })
  }

  update() {
    this.button.disabled = !this.isValid()
    var tooltip = document.getElementById('tooltip')
    if (tooltip != undefined) {
      if (this.button.disabled) {
        tooltip.classList.add('tooltip__link')
      } else {
        tooltip.classList.remove('tooltip__link')
      }
    }
    if (this.isValid() && document.getElementsByClassName("card-create").length > 0) {
      document.getElementsByClassName("process__step")[0]
        .classList.add("process__step--done")
    } else if (!this.isValid() && document.getElementsByClassName("card-create").length > 0) {
      document.getElementsByClassName("process__step")[0]
        .classList.remove("process__step--done")
    }
    if (this.isValid() && document.getElementsByClassName("card-description").length > 0) {
      document.getElementsByClassName("process__step")[1].classList.add("process__step--done")
    } else if (!this.isValid() && document.getElementsByClassName("card-description").length > 0) {
      document.getElementsByClassName("process__step")[1].classList.remove("process__step--done")
    }
    if (this.isValid() && document.getElementsByClassName("card-conditions").length > 0) {
      document.getElementsByClassName("process__step")[2].classList.add("process__step--done")
    } else if (!this.isValid() && document.getElementsByClassName("card-conditions").length > 0) {
      document.getElementsByClassName("process__step")[2].classList.remove("process__step--done")
    }
    if (this.isValid() && document.getElementsByClassName("card-files").length > 0) {
      document.getElementsByClassName("process__step")[3].classList.add("process__step--done")
    } else if (!this.isValid() && document.getElementsByClassName("card-files").length > 0) {
      document.getElementsByClassName("process__step")[3].classList.remove("process__step--done")
    }

  }

  isValid() {
    return [...this.inputs.values()]
      .filter(input => input.isDisplayed())
      .reduce((valid, input) => input.isValid() && valid, true)
  }
}

function isElementDisplayed(element) {
  return element.parentNode.offsetParent !== null
}

function isInputValue(input) {
  return input.checkValidity() &&
    (input.type !== 'hidden' || !input.hasAttribute('required') || input.value !== '' && input.value !== '[]')
}

class InputGroup extends Array {
  isDisplayed() {
    return this.find(isElementDisplayed)
  }

  isValid() {
    return undefined !== this.filter(isElementDisplayed).find(isInputValue)
  }
}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('[data-validate]').forEach(button => {
    new Validate(button, document.querySelector(button.getAttribute('data-validate')))
  })
});