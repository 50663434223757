import { createPopper } from "@popperjs/core";

class Popper {
  defaultOptions = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [4, 8],
        },
      },
    ],
  }

  constructor(triggerElement, tooltipElement, options = {}) {
    this.triggerElement = triggerElement
    this.tooltipElement = tooltipElement
    this.tooltipVisible = false
    this.options = Object.assign(this.defaultOptions, options)

    this.tooltipElement.insertAdjacentHTML("beforeend", '<div class="popper-arrow"></div>')
    this.popperInstance = createPopper(triggerElement, tooltipElement, this.options)

    this.triggerElement.addEventListener("click", this.onTriggerClick.bind(this))
    document.addEventListener("click", this.onDocumentClick.bind(this))
  }

  hideTooltip() {
    this.tooltipElement.removeAttribute("data-show")
    this.popperInstance.update()
    this.tooltipVisible = false
  }

  showTooltip() {
    this.tooltipElement.setAttribute("data-show", true)
    this.popperInstance.update()
    this.tooltipVisible = true
  }

  onTriggerClick(_event) {
    this.tooltipVisible ? this.hideTooltip() : this.showTooltip()
  }

  onDocumentClick(event) {
    if (this.tooltipVisible && !this.triggerElement.contains(event.target)) {
      this.hideTooltip()
    }
  }
}

$(document).on("turbolinks:load", () =>
  document.querySelectorAll(".popper-trigger").forEach(triggerElement =>
    triggerElement.parentElement.querySelectorAll(".popper-tooltip").forEach(tooltipElement =>
      new Popper(triggerElement, tooltipElement)
    )
  )
)
