import Vue from 'vue'
import Users from './Users.vue'

$(document).on('turbolinks:load', function() {

  document.querySelectorAll('[data-users]').forEach(element => {
    const props = JSON.parse(element.getAttribute('data-users'))
    new Vue({
      render(h) {
        return h(Users, {
          props: {
            value: this.$el.getAttribute('data-value'),
            id: this.$el.getAttribute('id'),
            name: this.$el.getAttribute('name'),
            required: this.$el.hasAttribute('required'),
            ...props,
          }
        })
      },
    }).$mount(element)
  })

})