document.turbolinksPersistScroll ||= {
  scrollPosition: null,
  enabled: false,
  initialize: (element) => {
    element.addEventListener("click", (_event) => document.turbolinksPersistScroll.enabled = true)
  }
}

document.addEventListener("turbolinks:before-visit", (event) => {
  document.turbolinksPersistScroll.scrollPosition = document.turbolinksPersistScroll.enabled ? window.scrollY : null
  document.turbolinksPersistScroll.enabled = false
})

document.addEventListener("turbolinks:load", (event) => {
  document.querySelectorAll('[data-turbolinks-persist-scroll="true"]').forEach(document.turbolinksPersistScroll.initialize)

  if (document.turbolinksPersistScroll.scrollPosition)
    window.scrollTo(0, document.turbolinksPersistScroll.scrollPosition)
})
