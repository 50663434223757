<script>
import axios from 'axios'
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable,
  },
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    type: { type: String, required: true },
    addLabel: { type: String, required: true },
    name: { type: String, required: true },
    url: { type: String, required: true },
    accept: { type: String, required: false, default: null },
    max: { type: Number, require: false, default: null },
    maxSize: { type: Number, required: false, default: null },
    companies: { type: Array, require: false, default: null },
    offerMission:{ type: String, required: false, default: "" },
    nameInput: { type: Boolean, required: false, default: true },
    autoNameInput: { type: Boolean, required: false, default: true },
    required: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      medias: JSON.parse(this.value),
      active_medias: JSON.parse(this.value),
      json: this.value,
      files: null,
      queue: [],
    }
  },
  computed: {
    processing() {
      return this.queue.filter(item => item.errors === null).length > 0
    },
    allowAdd() {
      return null === this.max || this.active_medias.length < this.max
    },
    highlight() {
      return this.type === 'photo' && this.queue.length + this.active_medias.length > 0
    },
    pdf() {
      return ['document', 'blueprint'].includes(this.type)
    }
  },
  methods: {
    onChange(media) {
      this.json = JSON.parse(this.json)
      const elem_index = this.json.findIndex((element) => element.id == media.id)
      this.json[elem_index].company_id = media.company_id
      this.json = JSON.stringify(this.json)

    },
    changeMediasName() {
      const checkName = new RegExp(/^Plan [1-9]+$/)
      this.active_medias.forEach((element, index) => {
        if (element.name === null || checkName.test(element.name)) {
          element.name = `Plan ${( index + 1 )}`
        }
      })
    },
    changeMediaName(media) {
     this.json = JSON.parse(this.json)
      const elem_index = this.json.findIndex((element) => element.id == media.id)
      this.json[elem_index].name = media.name
      this.json = JSON.stringify(this.json)
    },
    onFilesInput(event) {
      this.queue = this.queue.filter(item => item.errors === null)
      Array.from(event.target.files).forEach(file => {
        const reader = new FileReader();
        reader.onload = event => {
          const src = event.target.result
          if ( null === this.max || this.active_medias.length + this.queue.length < this.max ) {
            this.queue.push({ file, src, processing: false, errors: null })
            this.process()
          } else {}
        }
        reader.readAsDataURL(file)
      })

      this.$refs.files.value = null
    },
    process() {
      const item = this.queue.find(item => item.processing === false && item.errors === null)

      if (item) {
        this.upload(item)
      }
    },
    upload(item) {
      item.processing = true
      if ( item.size > this.maxSize ) {
        item.errors = ['Le fichier est trop volumineux']
        item.processing = false
        this.process()
      } else {
        const form = new FormData()
        form.append('file', item.file)

        axios
          .post(this.url, form)
          .then(response => {
            if ( response.data.byte_size > this.maxSize ) {
              item.errors = ['Le fichier est trop volumineux']
              item.processing = false
            } else {
              this.onSuccess(response)
              this.queue = this.queue.filter(i => i !== item)
            }
            this.process()
          })
          .catch(error => {
            if (error.response.data.errors) {
              item.errors = error.response.data.errors
            } else if (error.response.status === 413) {
              item.errors = ['Le fichier est trop volumineux']
            } else {
              item.errors = [`${error.response.status}: ${error.response.statusText}`]
            }

            item.processing = false
            this.process()
          })
      }
    },
    remove(media) {
      this.medias = this.medias.filter(item => item !== media)
      media._destroy = true
      this.medias.push(media)
    },
    onSuccess(response) {
      if (this.autoNameInput){
        this.medias.push({
        file: response.data.signed_id,
        name: "Plan " + ( this.active_medias.length + 1 ),
        type: response.data.type,
        preview: response.data.preview,
      })
      } else {
        if (this.companies && this.offerMission == "open") {
          this.medias.push({
        file: response.data.signed_id,
        name: null,
        type: response.data.type,
        preview: response.data.preview,
        company_id: this.companies[0].id
      })
        } else {
           this.medias.push({
        file: response.data.signed_id,
        name: null,
        type: response.data.type,
        preview: response.data.preview,
      })
        }

      }
    },
  },
  watch: {
    medias: {
      deep: true,
      handler(value) {
        this.json = JSON.stringify(value)
        this.active_medias = value.filter(item => item._destroy !== true)
        if (this.autoNameInput){
          this.changeMediasName()
        }
        // Trigger change event on input in order to be listen by outside component javascript
        this.$nextTick(() => this.$refs.json.dispatchEvent(new Event('change')))
      },
    },
  },
}
</script>

<template>

  <div class="form__medias">
    <input type="hidden" v-model="json" ref="json" :name="name" :id="id" :required="required">
    <input type="file" v-on:change="onFilesInput" ref="files" multiple :accept="accept">
    <Draggable
      v-model="medias"
      class="media-list"
      :class="{ 'media-list--highlight': highlight, 'media-list--pdf': pdf }"
      tag="ul"
      drag-class="media--dragged"
      draggable=".draggable"
      handle=".media__image"
    >
      <!-- media preview -->
      <li class="media draggable" v-for="(media, key) in active_medias" :key="media.file">
        <span class="media__image" :style="{ backgroundImage: media.preview ? `url('${media.preview}')` : 'none' }">
          <template v-if="pdf && !media.previewable && media.preview.includes('pdf')"><i class="icon icon--pdf" aria-hidden="true"></i>.pdf</template>
          <div class="media__delete tooltip">
            <button type="button" class="tooltip__link" data-tooltip="Supprimer le fichier" v-on:click="remove(media)">
              <i class="icon icon--cross" aria-hidden="true" ></i>
            </button>
          </div>
        </span>
        <input
          type="text"
          :id="`${id}_media_${key}`"
          class="media__rename"
          placeholder="Description"
          v-if="nameInput"
          v-model="medias.find(item => item.id === media.id).name"
          v-on:keydown.enter.prevent
        >
        <input
          type="text"
          :id="`${id}_media_${key}`"
          class="media__rename"
          v-if="autoNameInput"
          v-on:keydown.enter.prevent
          v-on:keyup="changeMediaName(media)"
          v-model="media.name"
        >
        <div class="form__select" v-if="companies !== null">
          <select v-model="media.company_id" @change="onChange(media)" >
            <option :value="null"></option>
            <option v-for="company in companies" :value="company.id"  v-bind:key="company.id">{{ company.name }}</option>
          </select>
        </div>
      </li>

      <!-- loading media preview -->
      <li v-for="(media, key) in queue" :key="`queue-item-${key}`" class="media" :class="{ 'media--error': media.errors !== null }">
        <label :for="`${id}_queue_${key}`" class="media__image">
          <span class="loader" v-if="media.processing"></span>
          <span v-if="media.errors" v-for="error in media.errors"  v-bind:key="error.id"  >
            <i class="icon icon--info"></i>
            {{ error }}
          </span>
        </label>
        <input type="text" :id="`${id}_queue_${key}`" class="media__rename" value="" disabled>
      </li>

      <!-- add button -->
      <li class="media-list__button" slot="footer" v-show="allowAdd">
        <button type="button" v-on:click="$refs.files.click()" :disabled="processing">
          <i class="icon icon--plus-circled" aria-hidden="true"></i>
          <template v-if="processing">Chargement...</template>
          <template v-else>{{ addLabel }}</template>
        </button>
      </li>
    </Draggable>
  </div>
</template>
