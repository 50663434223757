$(document).on("turbolinks:load", function() {
  const html2canvas = require("html2canvas");
  if (document.getElementsByClassName("address-check__input").length > 0) {
    check_box = document.getElementsByClassName("address-check__input")[0];
    check_box.addEventListener("change", (event) => {
      if (event.currentTarget.checked) {
        hideIcons();
        capture();
      }
    });
  }
  function capture() {
    html2canvas(document.getElementsByClassName("map__container")[0], {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      removeContainer: true,
    })
      .then(function(canvas) {
        document.getElementById("demand_screenshot_shape").value = canvas.toDataURL("image/png", 1);
        showIcons();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function hideIcons() {
    !!document.getElementsByClassName("gm-svpc")[0] ? document.getElementsByClassName("gm-svpc")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("gmnoprint")[0] ? document.getElementsByClassName("gmnoprint")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("map__zoom")[0] ? document.getElementsByClassName("map__zoom")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("map__type")[0] ? document.getElementsByClassName("map__type")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("gm-control-active")[0] ? document.getElementsByClassName("gm-control-active")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("gm-fullscreen-control")[0] ? document.getElementsByClassName("gm-fullscreen-control")[0].style.opacity = 0 : null
    !!document.getElementsByClassName("gm-bundled-control-on-bottom")[0] ? document.getElementsByClassName("gm-bundled-control-on-bottom")[0].style.opacity = 0 : null
  }

  function showIcons() {
    !!document.getElementsByClassName("gm-svpc")[0] ? document.getElementsByClassName("gm-svpc")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("gmnoprint")[0] ? document.getElementsByClassName("gmnoprint")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("map__zoom")[0] ? document.getElementsByClassName("map__zoom")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("map__type")[0] ? document.getElementsByClassName("map__type")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("gm-control-active")[0] ? document.getElementsByClassName("gm-control-active")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("gm-fullscreen-control")[0] ? document.getElementsByClassName("gm-fullscreen-control")[0].style.opacity = 1 : null
    !!document.getElementsByClassName("gm-bundled-control-on-bottom")[0] ? document.getElementsByClassName("gm-bundled-control-on-bottom")[0].style.opacity = 1 : null
  }
});
