$(document).on('turbolinks:load', function () {
  var input = document.getElementById("offer_address") || null;

  const firstRegExp = /^[0-9]{5} [a-zA-Z-éèàçâêÀÉÈôîï]+, [a-zA-Z]+$/g;
  const secondRegExp = /^[a-zA-Z-éèàçâêÀÉÈôîï]+, [a-zA-Z]+$/g;

  function checkAddress(el) {
    let valideAddress = document.getElementById("create_offer_location_validated");
    let errorMessage = valideAddress.parentElement;
    let element = el.target.value
    if (element.match(firstRegExp) || element.match(secondRegExp)) {
      valideAddress.setAttribute('disabled', true)
      errorMessage.classList.add('tooltip__link')
    } else {
      valideAddress.removeAttribute('disabled')
      errorMessage.classList.remove('tooltip__link')
    }
  }

  if (input) {
    input.addEventListener("blur", (e) => {
      setTimeout(() => {
        checkAddress(e)
      }, 500)
    })
  }
})