/*
This class computes content of a textarea and display it in the span:
Add css class to textarea 'count-content'
Add css class to span 'content-length'
Usage:
<textarea class="count-content" name="demand[discription]" maxlength="400"></textarea>
<div class="content-length">389/400</div>
*/
class CountContent {
  constructor(element, length_count) {
    this.element = element
    this.length_count = length_count
    this.init()
    this.counter()
  }

  init() {
    this.length_count.innerHTML = this.element.value.length + `/${parseInt(this.element.attributes.maxlength.value)}`
  }

  counter() {
    this.element.addEventListener("keyup", (event) => {
      this.length_count.innerHTML = event.target.value.length + `/${parseInt(event.target.attributes.maxlength.value)}`
    })
  }
}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('.count-content').forEach(element => {
    new CountContent(element, element.nextElementSibling)
  })

});