import Choices from 'choices.js'

const french = {
  loadingText: 'Chargement...',
  noResultsText: 'Aucun résultat',
  noChoicesText: 'Aucun choix possible',
  itemSelectText: 'Appuyer pour selectionner',
  addItemText: (value) => {
    return `Appuyer sur Entrer pour ajouter <b>"${value}"</b>`;
  },
  maxItemText: (maxItemCount) => {
    return `Seulement ${maxItemCount} éléments peuvent être ajoutés`;
  },
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-choices-text]').forEach(element => new Choices(element, {
    delimiter: ',',
    editItems: true,
    maxItemCount: 10,
    removeItemButton: true,
    ...french,
  }))
})
