import axios from 'axios'

export default class Suggestions
{
  constructor(element) {
    this.element = element
    this.container = element.querySelector('.place-suggestions')

    if (this.container) {
      this.hide()
    }
  }

  hide() {
    this.container.style.display = 'none'
  }

  show() {
    this.container.style.display = null
  }

  clear() {
    this.container.innerHTML = ''
  }

  getSuggestion(place) {
    if (!this.container) {
      return
    }

    axios
      .get(this.container.getAttribute('data-endpoint'), { params: { locationName: place.name } })
      .then(response => this.buildSuggestions(response.data))
      .catch(error => console.error(error))
  }

  buildSuggestions(suggestions) {
    if (suggestions.length > 0) {
      this.container.innerHTML = '<h4>Des offres sont déjà référencée à cette adresse :</h4>'
      const list = document.createElement('ul')
      list.classList.add('list-simple')
      suggestions.forEach(suggestion => list.append(this.buildSuggestion(suggestion)))
      this.container.append(list)
      this.show()
    } else {
      this.clear()
      this.hide()
    }
  }

  buildSuggestion(suggestion) {
    const item = document.createElement('li')
    item.innerHTML = `<a href="${suggestion.url}" target="_blank">${suggestion.formatted_ref} - ${suggestion.address}</a>`

    return item
  }
}
