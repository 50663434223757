export default function () {
    function check_range(event, min, max) {
    if (event.isComposing || event.keyCode === 229) {
        return;
    }
    if (parseInt(event.target.value) < min) {
        event.target.value = 0;
    }
    if (parseInt(event.target.value) > max) {
        event.target.value = 100;
    }
    }

    let selling_price_fees_percent = document.getElementById(
        "offer_selling_price_fees_percent"
    );
    let annual_rent_fees_percent = document.getElementById(
        "offer_annual_rent_fees_percent"
    );

    let offer_selling_price_representative_fees_percent = document.getElementById(
        "offer_selling_price_representative_fees_percent"
    );
    let offer_annual_rent_representative_fees_percent = document.getElementById(
        "offer_annual_rent_representative_fees_percent"
    );
    let selling_price_advisor_fees_percent = document.getElementById(
        "selling_price_advisor_fees_percent"
    );

    let annual_rent_advisor_fees_percent = document.getElementById(
        "annual_rent_advisor_fees_percent"
    );

    let arr = [
        selling_price_fees_percent,
        annual_rent_fees_percent,
        offer_selling_price_representative_fees_percent,
        offer_annual_rent_representative_fees_percent,
        annual_rent_advisor_fees_percent,
        selling_price_advisor_fees_percent
    ];
    arr.forEach((element) => {
        element.addEventListener("keyup", (event) => {
        check_range(event, 0, 100);
        if (element.id.includes('representative_fees_percent') || element.id.includes('advisor_fees_percent')  ) {
            let keyupEvent = new Event('keypress');
            element.dispatchEvent(keyupEvent)
        }
        });
    });
}