import { groupBy } from "underscore"

class exclusiveInputFields {

  constructor(fields) {
    this.fields = fields
    this.onInput()
    fields.forEach(field => field.addEventListener('input', () => this.onInput()))
  }

  onInput() {
    const fieldsWithValue = this.fieldsWithValue()
    const fieldsWithoutValue = this.fieldsWithoutValue()

    if (!fieldsWithValue.length) {
      this.enableFields(this.fields)
    } else {
      this.enableFields(fieldsWithValue)
      this.disableFields(fieldsWithoutValue)
    }
  }

  fieldsWithValue() {
    return this.fields.filter(field => field.value)
  }

  fieldsWithoutValue() {
    return this.fields.filter(field => !field.value)
  }

  enableFields(fields) {
    fields.forEach(field => {
      field.disabled = false
      this.enableClosestForm(field)
    })
  }

  disableFields(fields) {
    fields.forEach(field => {
      field.disabled = true
      field.setAttribute("value", null)
      this.disableClosestForm(field)
    })
  }

  enableClosestForm(field) {
    const closestForm = field.closest('.form')
    if (closestForm) closestForm.classList.remove('form--disabled')
  }

  disableClosestForm(field) {
    const closestForm = field.closest('.form')
    if (closestForm) closestForm.classList.add('form--disabled')
  }
}

$(document).on('turbolinks:load', function() {
  const inputFieldsGroups = Object.values(groupBy(document.querySelectorAll('[data-exclusive-fields-group]'), e => e.dataset.exclusiveFieldsGroup))
  inputFieldsGroups.forEach(inputFieldsGroup => new exclusiveInputFields(inputFieldsGroup))
});
