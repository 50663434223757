$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-sticky]').forEach(element => {
    const offset = element.offsetTop

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > offset) {
        element.classList.add('sticky');
      } else {
        element.classList.remove('sticky');
      }
    })
  })
})