class Fees
{
  constructor(one, another) {
    this.one = one
    this.another = another

    this.one.addEventListener('keypress', () => this.onKeyup(this.one, this.another))
    this.another.addEventListener('keypress', () => this.onKeyup(this.another, this.one))

    this.onKeyup(this.one, this.another, false)
    this.onKeyup(this.another, this.one, false)
  }

  onKeyup(one, another, clear = true) {
    const value = parseInt(one.value, 10)

    if (value) {
      another.value = 100 - value
    } else if (clear) {
      another.value = null
    }
  }
}

$(document).on('turbolinks:load', function() {
  const sellingPriceFees = document.querySelectorAll('[data-fees="sellingPrice"]')
  const annualRentFees = document.querySelectorAll('[data-fees="annualRent"]')

  if (sellingPriceFees.length > 0) new Fees(...sellingPriceFees)
  if (annualRentFees.length > 0) new Fees(...annualRentFees)
});
