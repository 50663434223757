import axios from "axios";

function getCompany(val) {
  var resultat = "";
  axios
    .get(
      `https://suggestions.pappers.fr/v2?q=${val}&cibles=nom_entreprise,siret`
    )
    .then((response) => {
      $(".suggestions-company").addClass("show");
      if (response.data.resultats_nom_entreprise.length > 0) {
        response.data.resultats_nom_entreprise.forEach((element) => {
          resultat += `<div class='item-company' data-company='${element.nom_entreprise}' data-siret='${element.siege.siret}'><p>${element.nom_entreprise}</p><i><span>${element.siege.siret}</span> - ${element.domaine_activite}</i></div>`;
        });
        $(".suggestions-company").html(resultat);
      } else if (response.data.resultats_siret.length > 0) {
        response.data.resultats_siret.forEach((element) => {
          resultat += `<div class='item-company' data-company='${element.nom_entreprise}' data-siret='${element.siege.siret}'><p>${element.nom_entreprise}</p><i><span>${element.siege.siret}</span> - ${element.domaine_activite}</i></div>`;
        });
        $(".suggestions-company").html(resultat);
      } else {
        resultat = "";
        $(".suggestions-company").html("<div>résultat vide</div>");
      }

      $(".item-company").each(function() {
        $(this).click(function(e) {
          $(".suggestions-company").removeClass("show");
          $(".suggestions-company").html("");
          $("#register_company_name").val($(this).attr("data-company") + " - "+ $(this).attr("data-siret"));
        });
      });
    })
    .catch((error) => console.error(error));
}

$(document).on("turbolinks:load", function() {
  $("#register_company_name").keyup(function() {
    if (this.value != "") {
      getCompany(this.value);
    } else {
      $(".suggestions-company").removeClass("show");
      $(".suggestions-company").html("");
    }
  });
});
