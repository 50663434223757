export default class OfferMarker {
  constructor(map, offer, markerImage, markerImageSelected) {
    this.map = map
    this.offer = offer
    this.markerImage = markerImage
    this.markerImageSelected = markerImageSelected
    this.position = new google.maps.LatLng(this.offer.latitude, this.offer.longitude)
    this.shape = this.offer.shape
    this.shape_zone = null
    this.marker = new google.maps.Marker({
      map: this.map,
      position: this.position,
      title: this.offer.reference,
      icon: this.markerImage,
      zIndex: 9998
    })
    this.card = document.querySelector(`#miniature-${offer.reference.replace(/\ /g, '')}`)
  }

  select() {
    this.marker.setIcon(this.markerImageSelected)
    this.marker.zIndex = 9999
    this.card != null ? this.card.classList.add('miniature--hover') : false
  }

  unselect() {
    if (this.shape_zone) {
      this.shape_zone.setMap(null)
    }
    this.marker.zIndex = 9998
    this.marker.setIcon(this.markerImage)
    this.card != null ? this.card.classList.remove('miniature--hover') : false
  }
}