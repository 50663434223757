function stopPropagation(event) {
  event.stopPropagation()
}

class MultiSelect
{
  constructor(element) {
    this.element = element
    this.input = element.querySelector('.multiselect__input')
    this.input.addEventListener('click', () => this.toggle())
    this.bullets = document.querySelectorAll('.bullet')
    this.close = this.close.bind(this)
    this.count()
    this.checkboxes().forEach(checkbox => checkbox.addEventListener('change', (e) => {
      if (e.target.getAttribute('id').includes('mobile_')) {
        document.querySelector(`#${e.target.getAttribute('id').replace('mobile_', '')}`).checked = e.target.checked

      } else {
        document.querySelector(`#mobile_${e.target.getAttribute('id')}`).checked = e.target.checked
      }
      this.count()
    }))
  }

  toggle() {
    if (this.isOpen()) {
      this.close()
    } else {
      this.open()
    }
  }

  isOpen() {
    return this.element.classList.contains('multiselect--open')
  }

  close() {
    this.element.classList.remove('multiselect--open')
    this.element.addEventListener('remove', stopPropagation)
    document.removeEventListener('click', this.close)
  }

  open() {
    this.element.classList.add('multiselect--open')
    this.element.addEventListener('click', stopPropagation)
    document.addEventListener('click', this.close)
  }

  checkboxes() {
    return [...this.element.querySelectorAll('.checkbox__input')]
  }

  count() {
    this.bullets.forEach((bullet) => {
      bullet.innerHTML = this.checkboxes().filter(checkbox => checkbox.checked).length
    })
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('.multiselect').forEach(element => new MultiSelect(element));
})
