export default class Controls
{
  constructor(gmap) {
    this.gmap = gmap
    this.init()
  }

  init() {
    this.zoomInButton = this.gmap.element.querySelector('[data-map-control="zoomIn"]')
    this.zoomInButton.addEventListener('click', () => this.gmap.map.setZoom(this.gmap.map.getZoom() + 1))
    this.zoomOutButton = this.gmap.element.querySelector('[data-map-control="zoomOut"]')
    this.zoomOutButton.addEventListener('click', () => this.gmap.map.setZoom(Math.max(this.gmap.map.getZoom() - 1, 1)))
    this.mapTypeIdInputs = this.gmap.element.querySelectorAll('[data-map-control="mapTypeId"]')
    this.mapTypeIdInputs.forEach(input => input.addEventListener('change', () => {
      if (input.checked) {
        this.gmap.map.setMapTypeId(input.value)
      }
    }))
  }
}
