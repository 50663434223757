class Enable
{
  constructor(element, config) {
    this.element = element
    this.input = element.querySelector('.form__input')
    this.config = config

    document.querySelectorAll(config.input).forEach(input => {
      if (input.value === this.config.value) {
        this.onChange(input)
      }

      input.addEventListener('change', event => this.onChange(event.target))
    })
  }

  onChange(input) {
    if (input.value === this.config.value && input.checked) {
      this.element.classList.remove('.form--disabled')
      this.input.disabled = null
    } else {
      this.element.classList.add('.form--disabled')
      this.input.disabled = true
    }
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-enable]').forEach(element => new Enable(
    element,
    JSON.parse(element.getAttribute('data-enable'))
  ))
});
