class InputFilled
{
  constructor(element, className) {
    this.element = element
    this.className = className
    this.onBlur(element)
    element.addEventListener('blur', () => this.onBlur())
  }

  onBlur() {
    if (!['text', 'number', 'email', 'tel', 'password'].includes(this.element.type)) {
      return
    }

    if (this.element.value !== null && this.element.value !== '' && this.element.value !== undefined) {
      this.element.classList.add(this.className)
    } else {
      this.element.classList.remove(this.className)
    }
  }
}

$(document).on('turbolinks:load', function() {
  document
    .querySelectorAll('[data-input-filled]')
    .forEach(form => {
      form.querySelectorAll('input').forEach(input => new InputFilled(input, form.getAttribute('data-input-filled')))
    })
});
