class Confirm {
  constructor(modal, triggers) {
    this.modal = modal
    this.triggers = triggers
    this.message = this.modal.getAttribute('data-message-confirme')
    this.input = this.modal.getAttribute('data-input-confirme')
    this.nextButton = document.getElementById('next-modal')
    this.closeButton = this.modal.querySelector('[data-confirm-close]')
    this.submitButton = this.modal.querySelector('[data-confirm-submit]')
    this.selectUser = document.getElementById('user-to-transfer')


    if (this.input) {
      document.getElementById(this.input).addEventListener("keyup", e => {
        this.checkMessage(e.target.value)
      })
    }

    if (this.selectUser) {
      var that = this
      this.selectUser.addEventListener('change',(e) => {
        that.appendParameter(e.target.value)
      })
    }
    this.close = this.close.bind(this)
    this.triggers.forEach(trigger => trigger.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      this.open(
        trigger.getAttribute('href'),
        trigger.getAttribute('data-method-trigger') || trigger.getAttribute('data-method'),
        trigger.getAttribute('data-remote'),
        trigger.getAttribute('data-turbolinks-persist-scroll')
      )
    }))

    if (this.nextButton) {
      var path = "#"
      this.nextButton.addEventListener("click", e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.nextButton) {
          path = this.nextButton.getAttribute('href') + (window.location.search  || '?user_id=-1')
          this.close()
        }
        if (this.message) this.open(path, 'delete')
      })
    }
  }

  open(href, method, remote, persistScroll) {
    if (href && this.submitButton) this.submitButton.setAttribute('href', href)
    if (method && this.submitButton) this.submitButton.setAttribute('data-method', method)
    if (remote != null && this.submitButton) this.submitButton.setAttribute('data-remote', remote)
    if (persistScroll && this.submitButton) document.turbolinksPersistScroll.initialize(this.submitButton)
    this.closeButton.addEventListener('click', this.close)
    this.modal.classList.add('popin--show')
    document.body.classList.add('prevent-scroll')
  }

  close() {
    if (this.submitButton) this.submitButton.setAttribute('href', '#')
    if (this.closeButton) this.closeButton.removeEventListener('click', this.close)
    this.modal.classList.remove('popin--show')
    document.body.classList.remove('prevent-scroll')
  }

  checkMessage(input) {
    if (input === this.message) {
      this.submitButton.removeAttribute('disabled')
    } else {
      this.submitButton.setAttribute('disabled', true)
    }
  }
  appendParameter(id){
    window.history.replaceState(null, null, `?user_id=${id}`);

  }
}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('[data-confirm-modal]').forEach(element => new Confirm(
    element,
    document.querySelectorAll(`[data-confirm-trigger="${element.getAttribute('data-confirm-modal')}"]`)
  ))
});