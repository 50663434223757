export default function() {
  document.querySelectorAll('.carrousel--video').forEach(element => {
    element.style.display = "block"
  })
  if(document.getElementsByClassName("carrousel--video").length > 0) {
    document.querySelectorAll('#slider_carousel_type').forEach(element => {
      element.style.display = "none"
    })
    document.querySelectorAll(".label-video").forEach(label =>{
      label.classList.add("checked-slider-type")
    })
  }
  if (document.querySelectorAll('#videos-slider').length > 0) {
    document.querySelectorAll("#videos-slider").forEach(element => {
      element.checked = "checked"
    })
  }
  if (document.querySelectorAll('input[name="slider_type"]')) {
    document.querySelectorAll('input[name="slider_type"]').forEach((elem) => {
      elem.addEventListener("change", function(event) {
        var item = event.target.value;
          if (item == "video"){
          document.querySelectorAll(".label-video").forEach(label =>{
            label.classList.add("checked-slider-type")
          })
          document.querySelectorAll(".label-photo").forEach(label =>{
            label.classList.remove("checked-slider-type")
          })
          document.querySelectorAll('.carrousel--video').forEach(element => {
            element.style.display = "block"
          })
          document.querySelectorAll('#slider_carousel_type').forEach(element => {
            element.style.display = "none"
          })
        }else {
          document.querySelectorAll(".label-video").forEach(label =>{
            label.classList.remove("checked-slider-type")
          })
          document.querySelectorAll(".label-photo").forEach(label =>{
            label.classList.add("checked-slider-type")
          })
          document.querySelectorAll('.carrousel--video').forEach(element => {
            element.style.display = "none"
          })
          document.querySelectorAll('#slider_carousel_type').forEach(element => {
            element.style.display = "block"
          })
        }
      });
    });
    }
}