export default function showCreateCompany(name) {
  document.querySelector('[data-create-company]').click()
  const companyName = document.getElementById('select_company_company_name')
  companyName.value = name

  if (companyName.value !== null && companyName.value !== '' && companyName.value !== undefined) {
    companyName.classList.add('input-filled')
  } else {
    companyName.classList.remove('input-filled')
  }
}
