export default class Carousel
{
  constructor(element) {
    this.element = element
    this.slider = element.querySelector('.slider')
    this.slides = element.querySelectorAll('.slide')
    this.pointer = 0
    this.previousButton = this.element.querySelector('.carrousel__nav--previous')
    this.nextButton = this.element.querySelector('.carrousel__nav--next')
    this.listeners = []
    this.updateButtons()

    if (this.previousButton) {
      this.previousButton.addEventListener('click', event => this.previous(event))
    }

    if (this.nextButton) {
      this.nextButton.addEventListener('click', event => this.next(event))
    }
  }

  on(name, callback) {
    this.listeners.push({ name, callback })
  }

  emit(name, event) {
    this.listeners.filter(listener => listener.name === name).forEach(listener => listener.callback(event))
  }

  next(event) {
    event.preventDefault()

    if (this.hasNext()) {
      this.setPosition(++this.pointer)
    }
  }

  previous(event) {
    event.preventDefault()

    if (this.hasPrevious()) {
      this.setPosition(--this.pointer)
    }
  }

  hasPrevious() {
    return this.pointer > 0
  }

  hasNext() {
    return this.pointer < this.slides.length - 1
  }

  setPosition(position) {
    this.pointer = position
    this.slider.style.transform = `translateX(${-position * 100}%)`
    this.updateButtons()
    this.emit('position', { position, total: this.slides.length, title: this.slides[position].getAttribute('title') })
  }

  updateButtons() {
    if (this.hasPrevious()) {
      this.previousButton.classList.remove('disabled')
    } else {
      this.previousButton.classList.add('disabled')
    }

    if (this.hasNext()) {
      this.nextButton.classList.remove('disabled')
    } else {
      this.nextButton.classList.add('disabled')
    }
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-carousel]').forEach(element => {
    new Carousel(element)
  });
});
