import axios from 'axios'

export default function () {
    document.querySelector(".message-recommandation").style.display = "none";
    document.querySelector(".message-error-recommandation").style.display = "none";
    document.querySelector('.recommandation-btn').addEventListener("click", (element) => {
        send_recommandation()
    })
    document.getElementById('register_email').addEventListener("keypress", (element) => {
        if (element.code == "Enter" || element.code == "NumpadEnter") {
            send_recommandation()
        }
    })
}

function send_recommandation() {
    var email = document.querySelector("#register_email").value
    if (email != "") {
        var validEmail = validateEmail(email)
        if (validEmail) {
            axios
                .post("/recommandation", { email: email })
                .then(response => {
                    document.querySelector(".message-recommandation").style.display = "block"
                    document.querySelector(".message-error-recommandation").style.display = "none"
                    document.querySelector("#register_email").value = "";
                    setTimeout(function () {
                        document.querySelector(".message-recommandation").style.display = "none"
                    }, 2000)
                })
                .catch(error => console.error(error))
        } else {
            document.querySelector(".message-recommandation").style.display = "none"
            document.querySelector(".message-error-recommandation").style.display = "block";
            setTimeout(function () {
                document.querySelector(".message-error-recommandation").style.display = "none"
            }, 2000)
        }
    }
}

function validateEmail(email) {
    const re = /[A-Za-z0-9._%+-]{2,}@[a-zA-Z0-9._%+-]{2,}([.]{1}[a-zA-Z0-9._%+-]{2,}|[.]{1}[a-zA-Z0-9._%+-]{2,}[.]{1}[a-zA-Z0-9._%+-]{2,})/;
    return re.test(String(email).toLowerCase());
}