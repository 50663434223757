import EventDispatcher from '../utils/EventDispatcher'

export default class Location extends EventDispatcher {
  constructor(element) {
    super(null)

    this.element = element
    this.longitudeInput = element.querySelector('.place-longitude')
    this.latitudeInput = element.querySelector('.place-latitude')
    this.validatedInput = element.querySelector('.place-validated')
    this.addressCheck = element.querySelector('.address-check')
    this.addressUpdate = element.querySelector('.address-update')

    this.placeInput = element.querySelector('.place-input')
    this.bounds = {
      sw: {
        lat: document.querySelector(".bounds_sw_lat"),
        lng: document.querySelector(".bounds_sw_lng")
      },
      ne: {
        lat: document.querySelector(".bounds_ne_lat"),
        lng: document.querySelector(".bounds_ne_lng")
      }
    }

    if (this.validatedInput) {
      this.validatedInput.checked = false
      this.setAddressCheckVisibility(false)
      this.changeAddressCheckAppearance(false)
      this.validatedInput.addEventListener('change', () => {
        this.setAddressCheckVisibility(!this.validatedInput.checked)
        this.changeAddressCheckAppearance(this.validatedInput.checked)
        this.element.scrollIntoView({ block: 'center' })
        this.emit(this.validatedInput.checked ? 'validated' : 'unvalidated')
      })
    }

    // Prevent enter to submit form when selecting location
    this.placeInput.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
      }
    })

    // Clear location when input is emptied
    this.placeInput.addEventListener('change', event => {
      if (this.placeInput.value === '') {
        this.clear()
      }
    })
  }

  setAddressCheckVisibility(visible) {
    this.addressCheck.style.display = visible ? null : 'none'
  }

  changeAddressCheckAppearance(visible) {
    if (!window.location.href.includes("offres")) {
      if (visible) {
        document.querySelector('.cover-precision').style.display = null
        document.querySelector('.card__footer').style.display = null
        this.addressCheck.style.display = null
        this.addressCheck.querySelector('.change-zone-text').style.display = null
        this.addressCheck.querySelector('.address-check__btn').style.display = 'none'
        this.addressCheck.querySelector('p').style.display = 'none'
        this.addressCheck.classList.add('address-update')
      } else {
        document.querySelector('.cover-precision').style.display = 'none'
        document.querySelector('.card__footer').style.display = 'none'
        this.addressCheck.querySelector('.change-zone-text').style.display = 'none'
        this.addressCheck.querySelector('.address-check__btn').style.display = null
        this.addressCheck.querySelector('p').style.display = null
        this.addressCheck.classList.remove('address-update')
      }
    }
  }

  clear() {
    this.longitudeInput.value  = null
    this.latitudeInput.value  = null

    this.placeInput.value  = null

    if (this.validatedInput) {
      this.validatedInput.checked = false
      this.setAddressCheckVisibility(true)
      this.emit('unvalidated')
    }
  }

  setPlace(place, bounds=null) {
    this.longitudeInput.value = place.geometry.location.lng()
    this.latitudeInput.value = place.geometry.location.lat()
    if (bounds !== null) {
      this.bounds.sw.lat.value = bounds.getSouthWest().lat()
      this.bounds.sw.lng.value = bounds.getSouthWest().lng()
      this.bounds.ne.lat.value = bounds.getNorthEast().lat()
      this.bounds.ne.lng.value = bounds.getNorthEast().lng()
    }
    if (this.validatedInput) {
      this.validatedInput.checked = false
      this.setAddressCheckVisibility(true)
    }
  }

  getAddressComponent(place, name) {
    const component = place.address_components.find(component => component.types.includes(name))

    return component ? component.short_name : null
  }

  disableValidatedInput() {
    if (this.validatedInput) {
      this.validatedInput.disabled = true
    }
  }

  enableValidatedInput() {
    if (this.validatedInput) {
      this.validatedInput.disabled = false
    }
  }

  lat() {
    return this.latitudeInput.value ? parseFloat(this.latitudeInput.value) : null
  }

  lng() {
    return this.longitudeInput.value ? parseFloat(this.longitudeInput.value) : null
  }

  move(latitude, longitude) {
    this.longitudeInput.value = longitude
    this.latitudeInput.value = latitude
    // this.geocodeLatLng(latitude, longitude).then((address) => {
    //   this.placeInput.value = address
    // })
  }

  geocodeLatLng(latitude, longitude) {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: latitude,
      lng: longitude,
    };

    return geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          return response.results[0].formatted_address
        }
      })
  }
}
