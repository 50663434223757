export default class EventDispatcher
{
  constructor() {
    this.listeners = []
  }

  on(name, callback) {
    this.listeners.push({ name, callback })
  }

  emit(name, event = {}) {
    this.listeners.filter(listener => listener.name === name).forEach(listener => listener.callback(event))
  }
}
