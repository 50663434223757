export default function () {

    document.getElementById("submit-update").addEventListener("click", check)
    document.getElementById("confirm-change").addEventListener("click", ConfirmedChange)

    const ex_email = document.getElementById('user_email').value
    var count = 0

    function check() {
        if (ex_email != document.getElementById('user_email').value && count == 0) {
        event.preventDefault()
        document.getElementById("change-email").click();
        }
    }

    function ConfirmedChange() {
        count++
        document.getElementById("submit-update").click();
    }

}
