class Modal
{
  constructor(element) {
    this.element = element

    this.openButtons = document.querySelectorAll(`[data-open-modal="#${element.getAttribute('id')}"]`)
    this.openButtons.forEach(button => button.addEventListener('click', event => {
      event.preventDefault()
      this.open()
    }))

    this.closeButtons = document.querySelectorAll(`[data-close-modal="#${element.getAttribute('id')}"]`)
    this.closeButtons.forEach(button => button.addEventListener('click', event => {
      event.preventDefault()
      this.close()
    }))
  }

  open() {
    this.element.classList.add('modal--open')
    document.body.classList.add('prevent-scroll')
  }

  close() {
    this.element.classList.remove('modal--open')
    document.body.classList.remove('prevent-scroll')
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-modal]').forEach(element => new Modal(element));
})
