class InputBinding
{
  constructor(elements) {
    this.elements = elements
    this.bind(this.elements)
  }

  bind(elements) {
    elements.forEach(element => {
      element.addEventListener('input', () => {
        Array.from(elements).filter((e) => { return e !== element }).forEach(e => {
          e.value = element.value
        })
      })
    });
  }
}

$(document).on('turbolinks:load', function() {
  var dataInputBindingAttributes = []
  document.querySelectorAll('[data-input-binding]').forEach(input => {
    dataInputBindingAttributes.push(input.getAttribute('data-input-binding'))
  })
  dataInputBindingAttributes.forEach(dataInputBindingAttribute => {
    new InputBinding(document.querySelectorAll(`[data-input-binding=${dataInputBindingAttribute}]`))
  })
});
