class ClipboardCopyableLink {
  constructor(element) {
    this.element = element
    this.clickable = true
    this.href = element.getAttribute("href")
    this.originalContent = element.innerHTML
    this.element.addEventListener("click", this.onClick.bind(this))
  }

  onClick(event) {
    event.preventDefault()
    event.stopPropagation()

    if (!this.clickable) return

    navigator.clipboard.writeText(this.href)

    this.clickable = false
    this.element.innerHTML = '<i class="fas fa-check-circle"></i> Lien copié !'

    setTimeout(() => {
      this.element.innerHTML = this.originalContent
      this.clickable = true
    }, 5000)
  }
}

$(document).on("turbolinks:load", () => {
  document.querySelectorAll("a.clipboard-copyable-link").forEach(element =>
    new ClipboardCopyableLink(element)
  )
})
