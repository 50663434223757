import France from './France'
import OfferMarker from './OfferMarker'
import Controls from './Controls'
import Autocomplete from './Autocomplete'
import EventDispatcher from '../utils/EventDispatcher'

export default class GMap extends EventDispatcher {
  constructor(element, init = true) {
    super(null)

    this.element = element
    this.container = this.element.querySelector('[data-map-container]')
    this.markerIcon = this.element.getAttribute('data-marker-icon')
    this.markerSelectedIcon = this.element.getAttribute('data-marker-selected-icon')
    this.fitbounds = this.element.hasAttribute('data-fitbounds')
    this.fitradius = parseInt(this.element.getAttribute('data-fitradius'), 10)
    this.drawingControl = null
    this.dataShape = []

    this.shapeInput = document.querySelector('.place-shape')
    this.allowDraw = this.element.hasAttribute('data-allow-draw')
    this.dataDraw = JSON.parse(this.element.getAttribute("data-draw"))
    this.colorShapeOptions = {
      // fillColor: "#8a9a9d",
      fillOpacity: 0.1,
      strokeWeight: 2,
      strokeColor: "#fff",
      clickable: true,
      editable: true,
      draggable: true,
      geodesic: true,
      zIndex: 1,
    }
    this.colorDrawShapeOptions = {
      // fillColor: "#8a9a9d",
      fillOpacity: 0.1,
      strokeWeight: 2,
      strokeColor: "#fff",
      zIndex: 1,
    }

    this.map = null
    this.autocomplete = null
    this.markers = []
    this.initialBoundsElements = {
      sw: {
        lat: document.querySelector(".bounds_sw_lat"),
        lng: document.querySelector(".bounds_sw_lng")
      },
      ne: {
        lat: document.querySelector(".bounds_ne_lat"),
        lng: document.querySelector(".bounds_ne_lng")
      }
    }
    this.search = this.element.hasAttribute('data-search')

    new Controls(this)
    this.checkButtonEmptySearch()
    if (init) {
      this.mapFromData()
      this.markersFromData().then((res) => {
        if (this.search) {
          this.map.addListener("zoom_changed", () => {
            this.markers.forEach(m => m.unselect())

            if (this.map.renderingType == "RASTER") {// to prevent second load
              this.getOffers()
            }
            this.checkButtonEmptySearch()
            this.changeBoundsValues()
          })
          this.map.addListener("dragend", () => {
            this.markers.forEach(m => m.unselect())

            document.querySelectorAll(".place-input").forEach((e) => {
              e.value = ''
            })
            this.getOffers()
            this.checkButtonEmptySearch()
            this.changeBoundsValues()
          })
        }
      });
    }
  }

  init(lat, lng, zoom) {
    this.map = new google.maps.Map(this.container, {
      center: { lat, lng },
      zoom,
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      zoomControl: false,
      minZoom: 5,
      maxZoom: 20

    })
    var thePanorama = this.map.getStreetView();

    google.maps.event.addListener(thePanorama, 'visible_changed', function () {
      if (thePanorama.getVisible()) {
        document.querySelector(".map__type").style.display = "none";
        document.querySelector(".map__zoom").style.display = "none";
      } else {
        document.querySelector(".map__type").style.display = "flex";
        document.querySelector(".map__zoom").style.display = "flex";
      }
    })
    this.map.setTilt(45);
    document.querySelectorAll('[data-autocomplete]').forEach(el => new Autocomplete(el, this.map))
    if (
      !!this.initialBoundsElements.sw.lat && parseFloat(this.initialBoundsElements.sw.lat.value) !== 0 &&
      !!this.initialBoundsElements.sw.lng && parseFloat(this.initialBoundsElements.sw.lng.value) !== 0 &&
      !!this.initialBoundsElements.ne.lat && parseFloat(this.initialBoundsElements.ne.lat.value) !== 0 &&
      !!this.initialBoundsElements.ne.lng && parseFloat(this.initialBoundsElements.ne.lng.value) !== 0
    ) {
      var southwest = {
        lat: parseFloat(this.initialBoundsElements.sw.lat.value),
        lng: parseFloat(this.initialBoundsElements.sw.lng.value)
      }
      var northeast = {
        lat: parseFloat(this.initialBoundsElements.ne.lat.value),
        lng: parseFloat(this.initialBoundsElements.ne.lng.value)
      }
      var shrunkBounds = this.slightlySmallerBounds(southwest, northeast);
      var newSouthwest = shrunkBounds[0];
      var newNortheast = shrunkBounds[1];
      this.map.fitBounds(
        new google.maps.LatLngBounds(newSouthwest, newNortheast)
      );
    }
    if (this.allowDraw) {
      this.setSettingsToDrawShapes()
    }
    if (this.dataDraw) {
      var map = this.map
      var colorShape = this.colorDrawShapeOptions
      const coords = JSON.parse(this.dataDraw.overlay)
      var shape;
      switch (this.dataDraw.shape_type) {
        case "polygon":
          shape = new google.maps.Polygon({
            paths: coords,
          });
          shape.setOptions(colorShape)
          shape.setMap(map);
          var bounds = new google.maps.LatLngBounds();
          var paths = shape.getPaths();
          var path;
          for (var i = 0; i < paths.getLength(); i++) {
              path = paths.getAt(i);
              for (var ii = 0; ii < path.getLength(); ii++) {
                  bounds.extend(path.getAt(ii));
              }
          }
          map.fitBounds(bounds);
          break;
        case "circle":
          shape = new google.maps.Circle({
            map,
            center: {lat: coords.center.lat, lng: coords.center.lng},
            radius: coords.radius,
          });
          shape.setOptions(colorShape)
          map.fitBounds(shape.getBounds());
          break;
        case "rectangle":
          shape = new google.maps.Rectangle({
            map,
            bounds: {
              north: coords.NE.lat,
              south: coords.SW.lat,
              east: coords.NE.lng,
              west: coords.SW.lng,
            },
          });
          shape.setOptions(colorShape)
          map.fitBounds(shape.getBounds());
         break;
        default:
          break;
      }
      map.addListener("maptypeid_changed", (e)=>{
        if (map.mapTypeId == 'satellite') {
          colorShape.strokeColor = "#fff"
        } else {
          colorShape.strokeColor = "#000"
        }
        shape.setOptions(colorShape)
      })
    }
  }

  slightlySmallerBounds(sw, ne) {
    function adjustmentAmount(value1, value2) {
      return Math.abs(value1 - value2) * 0.09; // 9%
    }

    var latAdjustment = adjustmentAmount(ne.lat, sw.lat);
    var lngAdjustment = adjustmentAmount(ne.lng, sw.lng);

    return [
      { lat: sw.lat + latAdjustment, lng: sw.lng + lngAdjustment },
      { lat: ne.lat - latAdjustment, lng: ne.lng - lngAdjustment }
    ]
  }

  mapFromData() {
    const lat = parseFloat(this.element.getAttribute('data-latitude')) || France.lat
    const lng = parseFloat(this.element.getAttribute('data-longitude')) || France.lng
    const zoom = parseInt(this.element.getAttribute('data-zoom'), 10) || 14

    this.init(lat, lng, zoom)
  }

  markersFromData() {
    return new Promise((resolve) => {
      if (this.element.hasAttribute('data-markers') && this.element.getAttribute('data-markers') !== 'null') {
        const offers = JSON.parse(this.element.getAttribute('data-markers')) || this.element.getAttribute('data-markers')

        const bounds = new google.maps.LatLngBounds()
        const markerImage = new google.maps.MarkerImage(this.markerIcon)
        const markerImageSelected = new google.maps.MarkerImage(this.markerSelectedIcon)

        offers.forEach(offer => {
          const marker = new OfferMarker(this.map, offer, markerImage, markerImageSelected)
          if (marker.card) {
            marker.marker.addListener('click', () => {
              this.markers.forEach(m => m.unselect())
              marker.select()
              if (marker.shape) {
                this.drawShapeZone(marker, marker.map)
              }
              marker.card.scrollIntoView({ block: 'center' })
            })
            marker.card.addEventListener('mouseover', () => {
              this.markers.forEach(m => m.unselect())
              marker.select()
            })
            marker.card.addEventListener('touchstart', () => {
              this.markers.forEach(m => m.unselect())
              marker.select()
            })
            marker.card.addEventListener('mouseleave', () => {
              marker.unselect()
            })
          }

          if (this.fitbounds) {
            bounds.extend(marker.position)
          }

          this.markers.push(marker)
        })

        if (document.getElementById("location_name") != null) {
          if (document.getElementById("location_name").value.length > 0) {
            const offer_empty = {
              latitude: parseFloat(document.getElementById("location_latitude").value),
              longitude: parseFloat(document.getElementById("location_longitude").value),
              reference: ''
            }
            const marker_search = new OfferMarker(this.map, offer_empty, '', '')
            this.markers.push(marker_search)
          }
        }
      }
      resolve('resolved');
    });
  }

  getOffers() {
    var that = this
    setTimeout(() => {
      document.querySelector(".map").setAttribute("data-latitude", that.map.center.lat())
      document.querySelector(".map").setAttribute("data-longitude", that.map.center.lng())

      $.get({
        url: this.buildSearchUrl(),
        success: function (data) {
          if (data) {
            for (let i = 0; i < that.markers.length; i++) {
              that.markers[i].marker.setMap(null);
            }
            that.markers = []
            that.markersFromData()
          }
        },
      });
    })
  }

  buildSearchUrl() {
    var that = this
    var urlToRequest = ""
    const url = new URL(window.location.href);
    url.searchParams.set('bounds[sw][lat]', that.map.getBounds().getSouthWest().lat())
    url.searchParams.set('bounds[sw][lng]', that.map.getBounds().getSouthWest().lng())
    url.searchParams.set('bounds[ne][lat]', that.map.getBounds().getNorthEast().lat())
    url.searchParams.set('bounds[ne][lng]', that.map.getBounds().getNorthEast().lng())
    url.searchParams.set('lat', that.map.center.lat())
    url.searchParams.set('lng', that.map.center.lng())
    url.searchParams.set('radius', '')
    url.searchParams.delete('type[]')
    document.querySelector("#offers-in-60") == null ? url.searchParams.delete('plus60') : null
    url.searchParams.set('search', document.querySelector("#search").value)
    this.getCheckedBoxesValues('type[]').forEach((value) => {
      url.searchParams.append('type[]', value)
    })
    url.searchParams.set('page', 1)
    window.history.replaceState(null, null, url); // or pushState
    if (window.location.href.includes("bounds")) {
      urlToRequest = window.location.href + "&format=js"
    } else {
      urlToRequest = window.location.href + "?format=js"
    }
    return urlToRequest
  }

  getCheckedBoxesValues(chkboxName) {
    var checkboxes = document.getElementsByName(chkboxName);
    var checkedCheckboxesValues = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkedCheckboxesValues.push(checkboxes[i].value);
      }
    }
    // Return the array if it is non-empty, or null
    return [...new Set(checkedCheckboxesValues)];
  }

  changeBoundsValues() {
    document.querySelector(".bounds_sw_lat").value = this.map.getBounds().getSouthWest().lat()
    document.querySelector(".bounds_sw_lng").value = this.map.getBounds().getSouthWest().lng()
    document.querySelector(".bounds_ne_lat").value = this.map.getBounds().getNorthEast().lat()
    document.querySelector(".bounds_ne_lng").value = this.map.getBounds().getNorthEast().lng()
  }

  checkButtonEmptySearch() {
    setTimeout(() => {
      window.history.replaceState(null, null, url);
      let url = new URL(window.location.href);
      let button_plus_60 = document.querySelector("#offers-in-60")
      if (button_plus_60 != null) {
        url.searchParams.set('plus60', '0')
        return document.querySelector("#offers-in-60").setAttribute("href", url)
      } else {
        url.searchParams.delete('plus60')
        return url
      }
    }, 500)
  }
  setSettingsToDrawShapes() {
    var dataDraw = []
    var shapeInput = this.shapeInput
     this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.CIRCLE,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      circleOptions: this.colorShapeOptions,
      polygonOptions: this.colorShapeOptions,
      rectangleOptions: this.colorShapeOptions,
    });
    var that = this
    google.maps.event.addListener(that.drawingManager, 'overlaycomplete', function (event) {
      var shape = event.overlay
      this.map.addListener("maptypeid_changed", (e)=>{
        if (that.map.mapTypeId == 'satellite') {
          that.colorShapeOptions.strokeColor = "#fff"
        } else {
          that.colorShapeOptions.strokeColor = "#000"
        }
        shape.setOptions(that.colorShapeOptions)
      })
      var coords
      switch (event.type) {
        case "polygon":
          coords = that.getShapeCoords(shape, event.type)
          google.maps.event.addListener(shape.getPath(), 'set_at', function() {
            coords = that.getShapeCoords(shape, event.type)
            shapeInput.value = JSON.stringify({ coords: coords, type: event.type })
          });
          break;
        case "circle":
          coords = that.getShapeCoords(shape, event.type)
          google.maps.event.addListener(shape, 'center_changed', function() {
            coords = that.getShapeCoords(shape, event.type)
            shapeInput.value = JSON.stringify({ coords: coords, type: event.type })
          });
          google.maps.event.addListener(shape, 'radius_changed', function() {
            coords = that.getShapeCoords(shape, event.type)
            shapeInput.value = JSON.stringify({ coords: coords, type: event.type })
          });
          break;
        case "rectangle":
          coords = that.getShapeCoords(shape, event.type)
          google.maps.event.addListener(shape, 'bounds_changed', function() {
            coords = that.getShapeCoords(shape, event.type)
            shapeInput.value = JSON.stringify({ coords: coords, type: event.type })
          });
          break;
        default:
          break;
      }
      dataDraw.push({ coords: coords, type: event.type })
      that.dataShape.push(shape)
      if (that.dataShape.length > 1) {
        that.dataShape[0].setMap(null)
        dataDraw.shift()
        that.dataShape.shift()
      }
      shapeInput.value = JSON.stringify(dataDraw[0])

    });
    this.drawingManager.setMap(this.map);
  }
  getShapeCoords(shape, type){
    var coords = {}
    switch (type) {
      case "polygon":
          coords = shape.getPath().getArray().map(coord => {
            return {
              lat: coord.lat(),
              lng: coord.lng()
            }
          });
          break;
        case "circle":
          coords = { radius: shape.getRadius(), center: { lat: shape.getCenter().lat(), lng: shape.getCenter().lng() } }
          break;
        case "rectangle":
          var bounds = shape.getBounds();
          coords = { NE: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() }, SW: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() } }
          break;
        default:
          break;
    }
    return coords;
  }

  drawShapeZone(marker,map){
    const colorShape = this.colorShapeOptions
    const coords = marker.shape.overlay
    switch ( marker.shape.shape_type ) {
      case "polygon":
        marker.shape_zone = new google.maps.Polygon({
          paths: coords,
          colorShape
        });
        marker.shape_zone.setMap(map);
        break;
      case "circle":
        marker.shape_zone = new google.maps.Circle({
          colorShape,
          map,
          center: {lat: coords.center.lat, lng: coords.center.lng},
          radius: coords.radius,
        });
        break;
      case "rectangle":
        marker.shape_zone = new google.maps.Rectangle({
          colorShape,
          map,
          bounds: {
            north: coords.NE.lat,
            south: coords.SW.lat,
            east: coords.NE.lng,
            west: coords.SW.lng,
          },
        });
       break;
      default:
        break;
    }
  }
}
