class Maxlength {
  constructor(element) {
    this.input = element
    this.maxlength = parseInt(this.input.getAttribute("maxlength")) || 0
    this.input.addEventListener("input", () => this.limitLength())
  }

  limitLength() {
    if (this.input.value.length > this.maxlength) {
      this.input.value = this.input.value.slice(0, this.maxlength)
    }
  }

}

$(document).on('turbolinks:load', function () {
  document.querySelectorAll('.maxlength').forEach(element => new Maxlength(element));
});