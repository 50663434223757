import Vue from 'vue'
import Medias from './Medias.vue'

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-medias]').forEach(element => {
    const props = JSON.parse(element.getAttribute('data-medias'))
    new Vue({
      render(h) {
        return h(Medias, {
          props: {
            value: this.$el.value,
            id: this.$el.getAttribute('id'),
            name: this.$el.getAttribute('name'),
            required: this.$el.hasAttribute('required'),
            ...props,
          }
        })
      },
    }).$mount(element)
  })
})
