<script>
  import axios from 'axios'
  import debounce from 'lodash.debounce'
  import showCreateCompany from './showCreateCompany'

  export default {
    props: {
      id: { type: String, required: true },
      endpoint: { type: String, required: true },
      value: { type: String, required: false, default: null },
      name: { type: String, required: true },
      allowCreate: { type: Boolean, require: false, default: false },
      required: { type: Boolean, require: false, default: false },
    },
    data() {
      return {
        search: null,
        showSuggestions: false,
        loading: false,
        suggestions: [],
        selection: this.value ? JSON.parse(this.value) : null,
        json: this.value,
        editing: !this.value,
      }
    },
    computed: {
      hasSearch() {
        return this.search && this.search.length > 0;
      },
      hasSuggestion() {
        return this.suggestions.length === 0
      },
      showCreateButton() {
        return !this.loading && this.allowCreate
      },
      showNoResult() {
        return !this.showCreateButton && this.hasSuggestion && !this.loading && this.search !== null && this.search.length > 0
      },
    },
    created() {
      this.debounceGetSuggestions = debounce(this.getSuggestions, 500)

      if (this.allowCreate && document.querySelectorAll('.create-company .form--error').length > 0) {
        this.create()
      }
    },
    methods: {
      create() {
        showCreateCompany(this.search)
      },
      getSuggestions() {
        this.loading = true
        axios
          .get(this.endpoint, { params: { search: this.search } })
          .then(response => {
            this.suggestions = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.error(error)
          })
      },
      select(company) {
        this.selection = { ...company }
        this.editing = false
      },
      selected(company) {
        return this.selection !== null && this.selection.id === company.id
      },
      hide() {
        this.showSuggestions = false
        document.removeEventListener('click', this.hide)
      },
      show() {
        this.showSuggestions = true
        document.addEventListener('click', this.hide)
      },
      edit() {
        this.editing = true
        this.$nextTick(function () {
          this.$refs.search.focus()
        })
      },
      clear() {
        if (this.editing) {
          this.selection = null
        }
      },
    },
    watch: {
      search() {
        if (this.search === null || this.search.length < 1) {
          this.suggestions = []
          return
        }

        this.debounceGetSuggestions()
      },
      selection(value) {
        this.json = value ? JSON.stringify(value) : null

        // Trigger change event on input in order to be listen by outside component javascript
        this.$nextTick(() => this.$refs.json.dispatchEvent(new Event('change')))
      },
    }
  }
</script>

<template>
  <div :id="id" class="dropdown-autocomplete">

    <input type="hidden" v-model="json" ref="json" :name="name" :required="required" :id="`${id}_data`">

    <div class="dropdown-autocomplete__input" v-if="selection" v-show="!editing" v-on:click.stop="edit()">
      <strong>{{ selection.name }}</strong>
    </div>
    <div v-show="editing">
      <input class="dropdown-autocomplete__input" type="search" ref="search" placeholder="Nom de la société, nom propre" v-model="search" v-on:focus='() =>{ show(); this.loading = true; this.debounceGetSuggestions()}' @input="search = $event.target.value" v-on:blur="clear()" v-on:click.stop>
      <div class="dropdown-autocomplete__menu" v-show="showSuggestions" v-on:click.stop>
        <div v-show="showNoResult" class="item">Aucun résultat</div>
        <div v-show="loading" class="item">Chargement...</div>
        <div v-for="suggestion in suggestions" class="item" v-on:click="select(suggestion)" v-bind:key="suggestion.id">
          <strong>{{ suggestion.name }}</strong>
          <span v-if="suggestion.locality">{{ suggestion.locality }}</span>
          <span v-if="suggestion.siret">SIRET : {{ suggestion.siret }}</span>
        </div>
        <div v-show="showCreateButton" class="item" v-on:click="create(search)">
          <i class="icon icon--plus" aria-hidden="true"></i>
          <span v-show="!search">Ajouter un mandant</span>
          <span v-show="search">Ajouter un mandant "{{ search }}"</span>
        </div>
      </div>
    </div>

  </div>
</template>
