// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Cookies from 'js.cookie';
window.Cookies = Cookies;
import "./gems.js.erb"


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
var $ = require("jquery");
window.jQuery = $;
window.$ = $
require('@fancyapps/fancybox');

import "@fortawesome/fontawesome-free/js/all";

import '../stylesheets/application'

import './../modules'
import '../components/medias'
import '../components/users'
import '../components/company'

require('./../modules/avatar')
require('./../modules/choices')
require('./../modules/confirm')
require('./../modules/enable')
require('./../modules/fees')
require('./../modules/flatpickr')
require('./../modules/input-filled')
require('./../modules/input-binding')
require('./../modules/lightbox')
require('./../modules/modal')
require('./../modules/multiselect')
require('./../modules/price')
require('./../modules/show')
require('./../modules/disable')
require('./../modules/sticky')
require('./../modules/validate')
require('./../modules/carousel')
require('./../modules/check_decimal')

require('./../modules/complete_register_agent')
require('./../modules/api_company')
require('./../modules/alert')
require('./../modules/turbolinks-persist-scroll')
require('./../modules/fixed-if-scrolled')
require('./../modules/popper')
require('./../modules/clipboard-copyable-link')
require('./../modules/exclusive-input-fields')

require('./../modules/utils/EventDispatcher')

require('./../modules/maps')

require('./../modules/play_video')

require('./../modules/shot_map_shape')
require('./../modules/limit_choice')
require('./../modules/count_content')
require('./../modules/first_step_offer')
require('./../modules/check_address')
require('./../modules/maxlenght')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
