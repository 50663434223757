class Avatar
{
  constructor(element) {
    this.element = element
    this.label = element.querySelector('label')
    this.input = element.querySelector('input[type="file"]')
    this.input.addEventListener('change', event => this.onChange(event))
  }

  onChange(event) {
    const file = event.target.files[0]
    if(file.size > 204800) {
      alert('Merci de choisir une image de taille inférieure à 200 !')
    } else {
      if (file) {
        const reader = new FileReader()
        reader.addEventListener('load', event => this.setBackgroundImage(event.target.result))
        reader.readAsDataURL(file)
      }
    }
  }

  setBackgroundImage(image) {
    this.label.style.backgroundImage = `url('${image}')`
    this.label.classList.remove('empty')
  }
}

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('.avatar-picker').forEach(element => new Avatar(element));
});