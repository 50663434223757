window.alertsInitializer ||= function () {
  if ($(".alert").length > 0) {
    $(".alert .close").click(function(){
      $(".alert").remove()
    })
    setTimeout(function () {
      $(".alert").addClass("hide-alert")
    }, 8000)
    setTimeout(function () {
      $(".alert").remove()
    }, 10000)
  }
}

$(document).on("turbolinks:load", window.alertsInitializer)
