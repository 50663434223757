import Vue from 'vue'
import Company from './Company.vue'
$(document).on('turbolinks:load', function() {
  document.querySelectorAll('[data-company]').forEach(element => {
    new Vue({
      render(h) {
        return h(Company, {
          props: {
            value: this.$el.value,
            id: this.$el.getAttribute('id'),
            endpoint: this.$el.getAttribute('data-company'),
            allowCreate: this.$el.hasAttribute('data-allow-create'),
            name: this.$el.getAttribute('name'),
            required: this.$el.hasAttribute('required'),
          },
        })
      },
    }).$mount(element)
  })
  
})
