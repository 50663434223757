$(document).on('turbolinks:load', function () {
  function ListnerChange() {
    var items = $('.offer_type_item:checked');
    if (items.length > 1) {
      $('.offer_type_item:not(:checked)').attr("disabled", true);
    } else {
      $('.offer_type_item:checkbox').attr("disabled", false);
    }
  }
  $('.offer_type_item').init(function () {
    ListnerChange()
    $(this).change(function () {
      ListnerChange()
    })
  });
});